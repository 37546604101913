var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.slide
    ? _c(
        "div",
        { attrs: { id: "verify-checkout-slide-component" } },
        [
          _c("slide-heading", { attrs: { heading: _vm.currentSlide.heading } }),
          _c(
            "div",
            { staticClass: "verify-checkout-intro-container" },
            [
              _c("slide-image-and-description", {
                attrs: { "override-description": _vm.slideDescription },
              }),
              !_vm.readOnly && !_vm.disableVerify
                ? _c("p", [
                    _vm._v("\n      Need to make a change?\n      "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "#",
                          "aria-label": "go back to document button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goToDocumentSlide()
                          },
                        },
                      },
                      [_vm._v("\n        Go back to document\n      ")]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "verify-checkout-slide-container" }, [
            _c("div", { staticClass: "verify-checkout-slide-body" }, [
              !_vm.disableVerify
                ? _c(
                    "div",
                    { staticClass: "verify-container" },
                    [
                      _c("schema-form-data-viewer", {
                        key: _vm.schemaFormDataViewerKey,
                        attrs: { "show-edit-link": true },
                        on: {
                          "edit-data": function ($event) {
                            return _vm.goToDocumentSlide()
                          },
                          refresh: function ($event) {
                            _vm.schemaFormDataViewerKey += 1
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.hideCart,
                      expression: "!hideCart",
                    },
                  ],
                  staticClass: "checkout-container",
                },
                [
                  _c("checkout-cart-body", {
                    on: {
                      "checkout-completed": function ($event) {
                        return _vm.$emit("next-slide")
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cart-header",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "checkout-cart-header" },
                                [
                                  _c("fa-icon", {
                                    staticClass: "cart-icon",
                                    attrs: { icon: "shopping-cart" },
                                  }),
                                  _c(
                                    "h5",
                                    { staticClass: "card-header-text" },
                                    [
                                      _vm._v(
                                        "\n                Cart Items\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1001602047
                    ),
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _c("ct-centered-spinner", [_vm._v("\n  Loading...\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }