<template>
  <div
    v-if="loaded && slide"
    id="verify-checkout-slide-component"
  >
    <slide-heading
      :heading="currentSlide.heading"
    />

    <div class="verify-checkout-intro-container">
      <slide-image-and-description :override-description="slideDescription" />

      <p v-if="!readOnly && !disableVerify">
        Need to make a change?
        <a
          href="#"
          aria-label="go back to document button"
          @click="goToDocumentSlide()"
        >
          Go back to document
        </a>
      </p>
    </div>

    <div class="verify-checkout-slide-container">
      <div class="verify-checkout-slide-body">
        <div v-if="!disableVerify" class="verify-container">
          <schema-form-data-viewer
            :key="schemaFormDataViewerKey"
            :show-edit-link="true"
            @edit-data="goToDocumentSlide()"
            @refresh="schemaFormDataViewerKey += 1"
          />
        </div>
        <div v-show="!hideCart" class="checkout-container">
          <checkout-cart-body @checkout-completed="$emit('next-slide')">
            <template #cart-header>
              <div class="checkout-cart-header">
                <fa-icon class="cart-icon" icon="shopping-cart" />
                <h5 class="card-header-text">
                  Cart Items
                </h5>
              </div>
            </template>
          </checkout-cart-body>
        </div>
      </div>
    </div>
  </div>

  <ct-centered-spinner v-else>
    Loading...
  </ct-centered-spinner>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: 'VerifyCheckoutSlide',
  components: {
    SlideHeading:             () => import('@/components/StagelineV2/shared/SlideHeading'),
    SchemaFormDataViewer:     () => import('@/components/SchemaForm/SchemaFormDataViewer'),
    CheckoutCartBody:         () => import('@/components/shared/CheckoutCartBody'),
    CtCenteredSpinner:        () => import('@/components/shared/CtCenteredSpinner'),
    SlideImageAndDescription: () => import('@/components/StagelineV2/shared/SlideImageAndDescription.vue'),
  },
  props: {
    slide: null,
    readOnly: null,
    disableVerify: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loaded: false,
      schemaFormDataViewerKey: 0,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentSlide',
    ]),
    ...mapGetters('checkout', [
      'cartEmpty',
    ]),
    slideDescription() {
      if (this.disableVerify) {
        return `<p>Verify your information and complete your purchase below to continue on your journey.</p>`
      }

      if (!this.cartEmpty) {
       return `<p>After checkout, you can download and print the document and finish filing yourself.</p>`
      }

      return this.slide.description
    },
    hideCart() {
      return this.cartEmpty
    },
  },
  mounted() {
    this.loaded = true
  },
  methods: {
    ...mapActions('stageline', [
      'goToSlide',
      'goToPdfSlideInCurrentStage',
    ]),
    async goToDocumentSlide() {
      await this.goToPdfSlideInCurrentStage()
    },
  },
}
</script>

<style lang="scss">
#verify-checkout-slide-component {
  width: 100%;
  padding: 0 !important;

  .verify-checkout-intro-container {
    width: 100%;
    padding-bottom: 1.0em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
  }

  .verify-checkout-slide-container {
    width: 100%;

    .verify-checkout-slide-body {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: flex-start;
      column-gap: 1.0em;
      row-gap: 1.0em;

      .verify-container {
        width: 100%;
      }

      .checkout-container {
        width: 100%;
        box-shadow: 1px 2px 5px grey;
        border-radius: 0.24em;
        height: min-content;
        padding: 0;

        .checkout-cart-body {
          .checkout-cart-header {
            padding: 1em 1.5em 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .cart-icon {
              padding-right: 0.5em;
            }
          }

          .card-header-text {
            margin-top: 0;
          }

          #select-payment {
            max-height: 44vh;
            overflow: scroll;

            h5.card-header-text {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  #verify-checkout-slide-component {

    .verify-checkout-slide-container {
      width: 96%;

      .verify-checkout-slide-body {
        flex-flow: column nowrap;
      }
    }
  }
}
</style>
